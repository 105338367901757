import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/error',
    name: 'error',
    meta: {
      menuBar: 'account',
    },
    component: () =>
      import('../pages/error')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      menuBar: 'account',
    },
    component: () =>
      import('../pages/loginModule/login')
  },
  {
    path: '/document/:documentId',
    name: 'document',
    meta: {
      menuBar: 'plat',
      firstMenu: 'document'
    },
    component: () =>
      import('../pages/document/document')
  },
  {
    path: '/template/templateList',
    name: 'templateList',
    meta: {
      menuBar: 'plat',
      firstMenu: 'template',
      secondMenu: 'templateList'
    },
    component: () =>
      import('../pages/template/templateList')
  },
  {
    path: '/template/editTemplate/:type/:id',
    name: 'editTemplate',
    meta: {
      menuBar: 'plat',
      firstMenu: 'template',
      secondMenu: 'templateList'
    },
    component: () =>
      import('../pages/template/editTemplate')
  },
  {
    path: '/template/templateCategory',
    name: 'templateCategory',
    meta: {
      menuBar: 'plat',
      firstMenu: 'template',
      secondMenu: 'templateCategory'
    },
    component: () =>
      import('../pages/template/templateCategory')
  },
  {
    path: '/template/templatePageList/:id',
    name: 'templatePageList',
    meta: {
      menuBar: 'plat',
      firstMenu: 'template',
      secondMenu: 'templateList'
    },
    component: () =>
      import('../pages/template/templatePageList')
  },
  {
    path: '/template/templateCommonList/:id',
    name: 'templateCommonList',
    meta: {
      menuBar: 'plat',
      firstMenu: 'template',
      secondMenu: 'templateList'
    },
    component: () =>
      import('../pages/template/templateCommonList')
  },
  {
    path: '/template/editTemplateCommon/:id/:type/:code',
    name: 'editTemplateCommon',
    meta: {
      menuBar: 'plat',
      firstMenu: 'template',
      secondMenu: 'templateList'
    },
    component: () =>
      import('../pages/template/editTemplateCommon')
  },
  {
    path: '/template/editTemplatePage/:type/:id/:templateId',
    name: 'editTemplatePage',
    meta: {
      menuBar: 'plat',
      firstMenu: 'template',
      secondMenu: 'templateList'
    },
    component: () =>
      import('../pages/template/editTemplatePage')
  },
  {
    path: '/template/editModelConfig/:pageCode/:templateCode',
    name: 'editModelConfig',
    meta: {
      menuBar: 'plat',
      firstMenu: 'template',
      secondMenu: 'templateList'
    },
    component: () =>
      import('../pages/template/editModelConfig')
  },
  {
    path: '/template/moduleList',
    name: 'moduleList',
    meta: {
      menuBar: 'plat',
      firstMenu: 'template',
      secondMenu: 'moduleList'
    },
    component: () =>
      import('../pages/template/moduleList')
  },
  {
    path: '/template/editModule/:type/:id',
    name: 'editModule',
    meta: {
      menuBar: 'plat',
      firstMenu: 'template',
      secondMenu: 'moduleList'
    },
    component: () =>
      import('../pages/template/editModule')
  },
 
  {
    path: '/product/productList',
    name: 'productList',
    meta: {
      menuBar: 'plat',
      firstMenu: 'product',
      secondMenu: 'productList'
    },
    component: () =>
      import('../pages/product/productList')
  },
  {
    path: '/product/productMenu',
    name: 'productMenu',
    meta: {
      menuBar: 'plat',
      firstMenu: 'product',
      secondMenu: 'productMenu'
    },
    component: () =>
      import('../pages/product/productMenu')
  },
  {
    path: '/product/editProductMenu/:type/:id',
    name: 'productMenu',
    meta: {
      menuBar: 'plat',
      firstMenu: 'product',
      secondMenu: 'productMenu'
    },
    component: () =>
      import('../pages/product/editProductMenu')
  },
  {
    path: '/product/productComponent',
    name: 'productComponent',
    meta: {
      menuBar: 'plat',
      firstMenu: 'product',
      secondMenu: 'productComponent'
    },
    component: () =>
      import('../pages/product/productComponent')
  },
  {
    path: '/product/editProductComponent/:type/:id',
    name: 'productComponent',
    meta: {
      menuBar: 'plat',
      firstMenu: 'product',
      secondMenu: 'productComponent'
    },
    component: () =>
      import('../pages/product/editProductComponent')
  },
  {
    path: '/product/commonPageList/:code',
    name: 'commonPageList',
    meta: {
      menuBar: 'plat',
      firstMenu: 'product',
      secondMenu: 'productList'
    },
    component: () =>
      import('../pages/product/commonPageList')
  },
  {
    path: '/product/editProductBase/:type/:id',
    name: 'editProductBase',
    meta: {
      menuBar: 'plat',
      firstMenu: 'product',
      secondMenu: 'productList'
    },
    component: () =>
      import('../pages/product/editProductBase')
  },
  {
    path: '/product/editProductDetail/:id',
    name: 'editProductDetail',
    meta: {
      menuBar: 'plat',
      firstMenu: 'product',
      secondMenu: 'productList'
    },
    component: () =>
      import('../pages/product/editProductDetail')
  },
  {
    path: '/product/productCategory',
    name: 'productCategory',
    meta: {
      menuBar: 'plat',
      firstMenu: 'product',
      secondMenu: 'productCategory'
    },
    component: () =>
      import('../pages/product/categoryList')
  },
  {
    path: '/template/packageList/:templateCode/:productCode',
    name: 'packageList',
    meta: {
      menuBar: 'plat',
      firstMenu: 'template',
      secondMenu: 'templateList'
    },
    component: () =>
      import('../pages/template/packageList')
  },
  {
    path: '/template/editPackage/:type/:id/:templateCode/:productCode',
    name: 'editPackage',
    meta: {
      menuBar: 'plat',
      firstMenu: 'template',
      secondMenu: 'templateList'
    },
    component: () =>
      import('../pages/template/editPackage')
  },
  {
    path: '/template/packageRightList/:code/:productCode',
    name: 'packageRightList',
    meta: {
      menuBar: 'plat',
      firstMenu: 'template',
      secondMenu: 'templateList'
    },
    component: () =>
      import('../pages/template/packageRightList')
  },
  {
    path: '/template/packageRightComponentList/:code',
    name: 'packageRightComponentList',
    meta: {
      menuBar: 'plat',
      firstMenu: 'template',
      secondMenu: 'templateList'
    },
    component: () =>
      import('../pages/template/packageRightComponentList')
  },
  {
    path: '/template/packageRightValueList/:code',
    name: 'packageRightValueList',
    meta: {
      menuBar: 'plat',
      firstMenu: 'template',
      secondMenu: 'templateList'
    },
    component: () =>
      import('../pages/template/packageRightValueList')
  },
  {
    path: '/user/userList',
    name: 'userList',
    meta: {
      menuBar: 'plat',
      firstMenu: 'user',
      secondMenu: 'userList'
    },
    component: () =>
      import('../pages/user/userList')
  },
  {
    path: '/user/userApplyList',
    name: 'userApplyList',
    meta: {
      menuBar: 'plat',
      firstMenu: 'user',
      secondMenu: 'userApplyList'
    },
    component: () =>
      import('../pages/user/userApplyList')
  },
  {
    path: '/userApplication/applicationList',
    name: 'applicationList',
    meta: {
      menuBar: 'plat',
      firstMenu: 'userApplication',
      secondMenu: 'applicationList'
    },
    component: () =>
      import('../pages/userApplication/applicationList')
  },
  {
    path: '/userApplication/applicationMenuList/:code',
    name: 'applicationMenuList',
    meta: {
      menuBar: 'plat',
      firstMenu: 'userApplication',
      secondMenu: 'applicationList'
    },
    component: () =>
      import('../pages/userApplication/applicationMenuList')
  },
  {
    path: '/userApplication/applicationComponentList/:code',
    name: 'applicationComponentList',
    meta: {
      menuBar: 'plat',
      firstMenu: 'userApplication',
      secondMenu: 'applicationComponentList'
    },
    component: () =>
      import('../pages/userApplication/applicationComponentList')
  },
  {
    path: '/funcList',
    name: 'funcList',
    meta: {
      menuBar: 'plat',
      firstMenu: 'funcList',
      secondMenu: 'funcList'
    },
    component: () =>
      import('../pages/func/funcList')
  },
 
  // {
  //   path: '/index',
  //   name: 'index',
  //   component: () =>
  //     import('../pages/index')
  // },
  // {
  //   path: '/client/normalClient',
  //   name: 'normalClient',
  //   component: () =>
  //     import('../pages/client/normalClient')
  // },
  // {
  //   path: '/client/normalClientInfo',
  //   name: 'normalClientInfo',
  //   component: () =>
  //     import('../pages/client/normalClientInfo')
  // },
  // {
  //   path: '/client/companyClient',
  //   name: 'companyClient',
  //   component: () =>
  //     import('../pages/client/companyClient')
  // },
  // {
  //   path: '/client/companyClientInfo',
  //   name: 'companyClientInfo',
  //   component: () =>
  //     import('../pages/client/companyClientInfo')
  // },
 
  // {
  //   path: '/product/categoryParams',
  //   name: 'categoryParams',
  //   component: () =>
  //     import('../pages/product/categoryParams')
  // },
  // {
  //   path: '/product/productOrderList',
  //   name: 'productOrderList',
  //   component: () =>
  //     import('../pages/product/productOrderList')
  // },

  // {
  //   path: '/application',
  //   name: 'application',
  //   component: () =>
  //     import('../pages/application')
  // },

  // {
  //   path: '/books',
  //   name: 'books',
  //   component: () =>
  //     import('../pages/books')
  // },


 
]

const router = new VueRouter({
  routes
})

export default router
