import http from '../http';

let menuApi = {}
//获取产品功能组件列表
const getPlatProductComponentList = function (pageNo, platProductCode,keywords) {
    const opts = {
        url: "/plat/api/pc/menu/platProduct/component/list",
        params: {
            pageNo,
            platProductCode,
            keywords
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取产品功能组件
const getPlatProductComponentInfo = function (componentCode) {
    const opts = {
        url: "/plat/api/pc/menu/platProduct/component/info",
        params: {
            componentCode
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//编辑产品功能组件｜code| 1 新增 2 编辑 3 删除 7 下架 8 上架
const editPlatProductComponent = function (editType, data) {
    const opts = {
        url: "/plat/api/pc/menu/platProduct/component/edit?editType=" + editType,
        params: data,
        contentType: 'application/json',
        apiUrl: 'common'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取产品菜单列表
const getPlatProductMenuList = function (pageNo, platProductCode, parentMenuCode, level) {
    const opts = {
        url: "/plat/api/pc/menu/platProduct/menu/list",
        params: {
            pageNo,
            platProductCode,
            parentMenuCode,
            level
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取产品菜单
const getPlatProductMenuInfo = function (menuCode) {
    const opts = {
        url: "/plat/api/pc/menu/platProduct/menu/info",
        params: {
            menuCode
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//编辑产品菜单｜(code)|type 1 新增 2 编辑 3 删除 8 上架 7 下架
const editPlatProductMenu = function (editType, data) {
    const opts = {
        url: "/plat/api/pc/menu/platProduct/menu/edit?editType=" + editType,
        params: data,
        contentType: 'application/json',
        apiUrl: 'common'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

//获取店铺应用的菜单列表
const getPlatApplicationMenuList = function (userAppCode,level,parentMenuCode) {
    const opts = {
        url: "/plat/api/pc/application/menu/list",
        params: {
            userAppCode,
            level,
            parentMenuCode
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//重建应用（店铺）管理平台菜单
const reubuildPlatApplicationMenu = function (userAppCode) {
    const opts = {
        url: "/plat/api/pc/application/menu/rebuild",
        params: {
            userAppCode
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取店铺功能组件列表）
const getPlatApplicationMcomponentList = function (userAppCode,keywords) {
    const opts = {
        url: "/plat/api/pc/application/mcomponent/list",
        params: {
            userAppCode,
            keywords
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//编辑店铺功能组件（code）|1 新增 2 编辑 3 删除
const editPlatApplicationMcomponentList = function (editType,userAppCode,data) {
    const opts = {
        url: "/plat/api/pc/application/mcomponent/edit?editType="+editType+"&userAppCode="+userAppCode,
        params: data,
        apiUrl: 'common',
        contentType: 'application/json'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

export default menuApi = {
    getPlatProductComponentList,
    editPlatProductComponent,
    getPlatProductMenuList,
    editPlatProductMenu,
    getPlatProductComponentInfo,
    getPlatProductMenuInfo,
    getPlatApplicationMenuList,
    reubuildPlatApplicationMenu,
    getPlatApplicationMcomponentList,
    editPlatApplicationMcomponentList,

}